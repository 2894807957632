import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
    RichTextField,
    ReferenceArrayField,
    Datagrid,
    TextField,
    ShowButton,
    ArrayField,
    ReferenceField,
} from 'react-admin';

import choices from '../choices';

const BuildProductSpecsField = ({ record, ...rest }) => (
    <Box display="flex" flexDirection="column">
        <Labeled label="Customizable">
            <BooleanField
                source="specs.customizable"
                record={record}
            />
        </Labeled>
        <Labeled label="CPU Brand">
            <SelectField
                source="specs.cpu_brand"
                choices={choices.cpu_brands}
                record={record}
            />
        </Labeled>
        <Labeled label="Sistema Operativo">
            <RichTextField
                source="specs.operativeSystem"
                record={record}
            />
        </Labeled>
        <Labeled label="Monitor">
            <ReferenceArrayField
                reference="products"
                source="specs.monitor.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción monitor">
            <RichTextField
                source="specs.monitor.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Mouse">
            <ReferenceArrayField
                reference="products"
                source="specs.mouse.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción mouse">
            <RichTextField
                source="specs.mouse.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Teclado">
            <ReferenceArrayField
                reference="products"
                source="specs.keyboard.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción teclado">
            <RichTextField
                source="specs.keyboard.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Conectividad">
            <RichTextField
                source="specs.connectivity"
                record={record}
            />
        </Labeled>
        <Labeled label="Puertos">
            <RichTextField
                source="specs.ports"
                record={record}
            />
        </Labeled>

        <Labeled label="Mother">
            <ReferenceArrayField
                reference="products"
                source="specs.mother.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción mother">
            <RichTextField
                source="specs.mother.description"
                record={record}
            />
        </Labeled>
        <Labeled label="CPU">
            <ReferenceArrayField
                reference="products"
                source="specs.cpu.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción CPU">
            <RichTextField
                source="specs.cpu.description"
                record={record}
            />
        </Labeled>
        <Labeled label="CPU Cooler">
            <ReferenceArrayField
                reference="products"
                source="specs.cpuCooler.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción CPU Cooler">
            <RichTextField
                source="specs.cpuCooler.description"
                record={record}
            />
        </Labeled>
        <Labeled label="RAM">
            <ReferenceArrayField
                reference="products"
                source="specs.ram.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción RAM">
            <RichTextField
                source="specs.ram.description"
                record={record}
            />
        </Labeled>
        <Labeled label="GPU">
            <ReferenceArrayField
                reference="products"
                source="specs.gpu.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción GPU">
            <RichTextField
                source="specs.gpu.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Almacenamiento">
            <ReferenceArrayField
                reference="products"
                source="specs.storage.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción almacenamiento">
            <RichTextField
                source="specs.storage.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Gabinete">
            <ReferenceArrayField
                reference="products"
                source="specs.case.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción gabinete">
            <RichTextField
                source="specs.case.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Fuente">
            <ReferenceArrayField
                reference="products"
                source="specs.power.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción fuente">
            <RichTextField
                source="specs.power.description"
                record={record}
            />
        </Labeled>
        <Labeled label="Fans">
            <ReferenceArrayField
                reference="products"
                source="specs.fans.products"
                record={record}
                {...rest}
            >
                <Datagrid style={{ tableLayout: 'fixed' }}>
                    <TextField source="name" />
                    <TextField source="code" />
                    <ShowButton />
                </Datagrid>
            </ReferenceArrayField>
        </Labeled>
        <Labeled label="Descripción Fans">
            <RichTextField
                source="specs.fans.description"
                record={record}
            />
        </Labeled>
        <ArrayField
            source="specs.games_compatibility"
            label="Juegos Compatibles"
            record={record}
            {...rest}
        >
            <Datagrid style={{ tableLayout: 'fixed' }}>
                <ReferenceField
                    reference="games"
                    source="game"
                    label="Game"
                >
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField label="Compatible" source="isCompatible" />
            </Datagrid>
        </ArrayField>
    </Box>
);

export default BuildProductSpecsField;
