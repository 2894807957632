import * as React from 'react';
import { useFormState } from 'react-final-form';
import {
    SelectInput,
    TextInput,
    NumberInput,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import { required } from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import choices from '../choices';
import GameCompatibilityInput from '../Builds/GameCompatibilityInput';
import MonitorProductSpecsInput from './MonitorProductSpecsInput';
import MouseProductSpecsInput from './MouseProductSpecsInput';
import KeyboardProductSpecsInput from './KeyboardProductSpecsInput';
import HeadsetProductSpecsInput from './HeadsetProductSpecsInput';
import ChairProductSpecsInput from './ChairProductSpecsInput';
import RAMProductSpecsInput from './RAMProductSpecsInput';
import CpuProductSpecsInput from './CpuProductSpecsInput';
import GpuProductSpecsInput from './GpuProductSpecsInput';
import CaseFanProductSpecsInput from './CaseFanProductSpecsInput';
import CpuCoolerProductSpecsInput from './CpuCoolerProductSpecsInput';
import MotherProductSpecsInput from './MotherProductSpecsInput';
import PowerProductSpecsInput from './PowerProductSpecsInput';
import StorageProductSpecsInput from './StorageProductSpecsInput';
import CaseProductSpecsInput from './CaseProductSpecsInput';
import BuildProductSpecsInput from './BuildProductSpecsInput';

export default (props) => {
    const { values } = useFormState();

    const specsForTypes = {
        case: <CaseProductSpecsInput {...props} />,
        power: <PowerProductSpecsInput {...props} />,
        gpu: <GpuProductSpecsInput {...props} />,
        storage: <StorageProductSpecsInput record={values} />,
        cpu_cooler: <CpuCoolerProductSpecsInput record={values} />,
        cpu: <CpuProductSpecsInput />,
        ram: <RAMProductSpecsInput />,
        mother: <MotherProductSpecsInput record={values} />,
        mouse: <MouseProductSpecsInput />,
        monitor: <MonitorProductSpecsInput />,
        build: <BuildProductSpecsInput />,
        case_fan: <CaseFanProductSpecsInput />,
        keyboard: (
            <KeyboardProductSpecsInput />
        ),
        headset: (
            <HeadsetProductSpecsInput />
        ),
        chair: (
            <ChairProductSpecsInput />
        ),
    };
    return specsForTypes[values.type] ?? <br />;
};
