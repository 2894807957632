import * as React from 'react';
import {
    SelectInput,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { required } from 'ra-core';

import choices from '../choices';
import GameCompatibilityInput from '../Builds/GameCompatibilityInput';
import { CoolerTypes } from '../../../helpers/types';

const BuildProductSpecsInput = () => (
    <>
        <BooleanInput
            label="Customizable"
            source="specs.customizable"
            defaultValue
        />
        <SelectInput
            label="CPU Brand"
            source="specs.cpu_brand"
            choices={choices.cpu_brands}
            validate={[required()]}
        />
        <RichTextInput
            source="specs.operativeSystem"
            label="Sistema Operativo"
        />
        <ReferenceArrayInput label="Monitor" reference="products" allowDuplicates source="specs.monitor.products" filter={{ type: 'monitor' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.monitor.description"
            label="Descripción monitor. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="Mouse" reference="products" allowDuplicates source="specs.mouse.products" filter={{ type: 'mouse' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.mouse.description"
            label="Descripción mouse. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <RichTextInput
            source="specs.connectivity"
            label="Conectividad"
        />
        <RichTextInput
            source="specs.ports"
            label="Puertos"
        />
        <ReferenceArrayInput label="Teclado" reference="products" allowDuplicates source="specs.keyboard.products" filter={{ type: 'keyboard' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.keyboard.description"
            label="Descripción teclado. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="Mother" allowDuplicates reference="products" source="specs.mother.products" filter={{ type: 'mother' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.mother.description"
            label="Descripción mother. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="CPU" allowDuplicates reference="products" source="specs.cpu.products" filter={{ type: 'cpu' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.cpu.description"
            label="Descripción CPU. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="CPU Cooler" allowDuplicates reference="products" source="specs.cpuCooler.products" filter={{ type: 'cpu_cooler', specs: { cooler_type: CoolerTypes.WATER_COOLER } }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.cpuCooler.description"
            label="Descripción CPU Cooler. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="RAM" allowDuplicates reference="products" source="specs.ram.products" filter={{ type: 'ram' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.ram.description"
            label="Descripción RAM. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="GPU" allowDuplicates reference="products" source="specs.gpu.products" filter={{ type: 'gpu' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.gpu.description"
            label="Descripción GPU. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="Almacenamiento" allowDuplicates reference="products" source="specs.storage.products" filter={{ type: 'storage' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.storage.description"
            label="Descripción almacenamiento. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="Gabinete" allowDuplicates reference="products" source="specs.case.products" filter={{ type: 'case' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.case.description"
            label="Descripción gabinete. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
        />
        <ReferenceArrayInput label="Fuente" allowDuplicates reference="products" source="specs.power.products" filter={{ type: 'power' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.power.description"
            label="Descripción fuente. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"

        />
        <ReferenceArrayInput label="Fans" allowDuplicates reference="products" source="specs.fans.products" filter={{ type: 'case_fan' }}>
            <AutocompleteArrayInput optionText="niceAdminName" />
        </ReferenceArrayInput>
        <RichTextInput
            source="specs.fans.description"
            label="Descripción fans. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"

        />
        <GameCompatibilityInput />
    </>
);

export default BuildProductSpecsInput;
